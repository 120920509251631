import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export default () => (
  <Layout>
    <Helmet titleTemplate="%s">
      <title>Thank you!</title>
      <meta
        name="description"
        content="Thank you for the message!"
      />
    </Helmet>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you for the message!</h1>
        </div>
      </div>
    </section>
  </Layout>
);
